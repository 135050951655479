<template>
  <div>
    <app-bar title="Datenimport" />
    <v-container>
      <v-row>
        <v-col>
          <v-file-input
            truncate-length="15"
            accept="application/zip"
            label="CSV-Datei auswählen"
            v-model="selectedFile"
          ></v-file-input>
          <v-btn depressed color="primary" @click="upload()">Import</v-btn>

          <v-checkbox
            v-model="autoPublish"
            label="Sofort veröffentlichen bei erfolg?"
          ></v-checkbox>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import AppBar from "../components/views/AppBar.vue";
import CarDataService from "../services/CarDataService";

export default {
  name: "import",
  data() {
    return {
      selectedFile: undefined,
      autoPublish: false,
    };
  },
  methods: {
    upload() {
      let vm = this;

      if (!this.selectedFile) {
        return;
      }

      CarDataService.import(this.selectedFile, this.autoPublish).then(() => {
        vm.$notify({
          title: "Import erfolgreich",
          text: "Die Datei wurde erfolgreich importiert",
          type: "success",
        });

        vm.$router.push({ name: "Home"});
      }).catch(() => {
        vm.$notify({
          title: "Import fehlgeschlagen",
          text: "Die Datei konnte nicht importiert werden.",
          type: "error",
        });
      });
    },
  },
  components: {
    AppBar,
  },
};
</script>
