import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('app-bar',{attrs:{"title":"Datenimport"}}),_c(VContainer,[_c(VRow,[_c(VCol,[_c(VFileInput,{attrs:{"truncate-length":"15","accept":"application/zip","label":"CSV-Datei auswählen"},model:{value:(_vm.selectedFile),callback:function ($$v) {_vm.selectedFile=$$v},expression:"selectedFile"}}),_c(VBtn,{attrs:{"depressed":"","color":"primary"},on:{"click":function($event){return _vm.upload()}}},[_vm._v("Import")]),_c(VCheckbox,{attrs:{"label":"Sofort veröffentlichen bei erfolg?"},model:{value:(_vm.autoPublish),callback:function ($$v) {_vm.autoPublish=$$v},expression:"autoPublish"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }